<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="保单号" prop="insuranceNo">
                <a-input v-model="queryParam.insuranceNo" placeholder="请输入保单号" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="车辆自编号" prop="vehicleNo">
                <a-input v-model="queryParam.vehicleNo" placeholder="请输入车辆自编号" allow-clear />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="保险公司名称" prop="name">
                  <a-input v-model="queryParam.name" placeholder="请输入保险公司名称" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="保单类型" prop="insurancePolicyType">
                  <a-select
                    placeholder="请选择保单类型"
                    v-model="queryParam.insurancePolicyType"
                    style="width: 100%"
                    allow-clear
                  >
                    <a-select-option
                      v-for="(d, index) in insurancePolicyTypeOptions"
                      :key="index"
                      :value="d.dictValue">{{ d.dictLabel }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="起始日期">
                  <a-range-picker
                    style="width: 100%"
                    v-model="daterangeInsuranceStartDate"
                    valueFormat="YYYY-MM-DD"
                    format="YYYY-MM-DD"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="结束日期">
                  <a-range-picker
                    style="width: 100%"
                    v-model="daterangeInsuranceEndDate"
                    valueFormat="YYYY-MM-DD"
                    format="YYYY-MM-DD"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="数据状态">
                  <a-select placeholder="请选择数据状态" v-model="queryParam.status" style="width: 100%" allow-clear>
                    <a-select-option v-for="(d, index) in statusOptions" :key="index" :value="d.dictValue">{{
                      statusMap[d.dictValue]
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery" id="tableQueryBtn"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
        <div class="table-operations">
          <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['tenant:iot:insurance:add']">
            <a-icon type="plus" />新增
          </a-button>
          <a-button
            type="primary"
            :disabled="single"
            @click="$refs.createForm.handleUpdate(undefined, ids)"
            v-hasPermi="['tenant:iot:insurance:edit']"
          >
            <a-icon type="edit" />修改
          </a-button>
          <a-button
            type="danger"
            :disabled="multiple"
            @click="handleDelete"
            v-hasPermi="['tenant:iot:insurance:remove']"
          >
            <a-icon type="delete" />删除
          </a-button>
          <a-button
            type="primary"
            @click="$refs.importExcel.importExcelHandleOpen()"
            v-hasPermi="['tenant:iot:insurance:import']"
          >
            <a-icon type="import" />导入
          </a-button>
          <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:iot:insurance:export']">
            <a-icon type="download" />导出
          </a-button>
          <!-- <a-button
          type="dashed"
          shape="circle"
          :loading="loading"
          :style="{ float: 'right' }"
          icon="reload"
          @click="getList"
        /> -->
        </div>
      </a-toolbar>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        :insurancePolicyTypeOptions="insurancePolicyTypeOptions"
        :statusOptions="statusOptions"
        @ok="getList"
      />
      <!-- 上传文件 -->
      <import-excel ref="importExcel" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        tid="1"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, getCheckboxProps }"
        :pagination="false"
      >
        <span slot="insurancePolicyType" slot-scope="text, record" :title="insurancePolicyTypeFormat(record)">
          {{ insurancePolicyTypeFormat(record) }}
        </span>
        <span slot="insuranceStartDate" slot-scope="text, record">
          {{ parseTime(record.insuranceStartDate) }}
        </span>
        <span slot="insuranceEndDate" slot-scope="text, record">
          {{ parseTime(record.insuranceEndDate) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['tenant:iot:insurance:details']" />
          <a
            @click="$refs.createForm.handleUpdate(record, undefined, 'onlyRead')"
            v-hasPermi="['tenant:iot:insurance:details']"
          >
            <a-icon type="info-circle" />查看
          </a>
          <a-divider type="vertical" v-hasPermi="['tenant:iot:insurance:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['tenant:iot:insurance:edit']">
            <a-icon type="edit" />修改
          </a>
          <span v-if="record.status === 0">
            <a-divider type="vertical" v-hasPermi="['tenant:iot:insurance:remove']" />
            <a @click="handleDelete(record)" v-hasPermi="['tenant:iot:insurance:remove']">
              <a-icon type="delete" />删除
            </a>
          </span>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
    <!-- <a-modal title="Title" :visible="visible" :confirm-loading="employeeLoading" @ok="handleOk" @cancel="handleCancel">
      <a-transfer
        :data-source="employeeData"
        show-search
        :filter-option="filterOption"
        :target-keys="targetKeys"
        :render="item => item.title"
        @change="handleChange"
        @search="handleSearch"
      ></a-transfer>
    </a-modal> -->
  </page-header-wrapper>
</template>

<script>
import { listInsurance, exportInsurance, changeStatusInsurance } from '@/api/iot/insurance'
// import { listEmployeeAccount } from '@/api/iot/employeeAccount'
import CreateForm from './modules/CreateForm'
import ImportExcel from './modules/ImportExcel'

export default {
  name: 'Insurance',
  components: {
    CreateForm,
    ImportExcel
  },
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 保单类型(字典)字典
      insurancePolicyTypeOptions: [],
      // 起始日期时间范围
      daterangeInsuranceStartDate: [],
      // 结束日期时间范围
      daterangeInsuranceEndDate: [],
      // 是否删除 0否1是字典
      statusOptions: [],
      // 查询参数
      queryParam: {
        insuranceNo: null,
        vehicleNo: null,
        name: null,
        insurancePolicyType: null,
        insuranceStartDate: null,
        insuranceEndDate: null,
        status: '0',
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '保单号',
          dataIndex: 'insuranceNo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '车辆自编号',
          dataIndex: 'vehicleNo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '车牌号',
          dataIndex: 'licensePlateNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '保险公司名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '保单类型(字典)',
          dataIndex: 'insurancePolicyType',
          scopedSlots: { customRender: 'insurancePolicyType' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '起始日期',
          dataIndex: 'insuranceStartDate',
          scopedSlots: { customRender: 'insuranceStartDate' },
          ellipsis: true,
          customRender: (text, record, index) => {
            return record.insuranceStartDate && record.insuranceStartDate.split(' ')[0]
          },
          align: 'center'
        },
        {
          title: '结束日期',
          dataIndex: 'insuranceEndDate',
          scopedSlots: { customRender: 'insuranceEndDate' },
          ellipsis: true,
          customRender: (text, record, index) => {
            return record.insuranceEndDate && record.insuranceEndDate.split(' ')[0]
          },
          align: 'center'
        },
        {
          title: '状态',
          dataIndex: 'status',
          width: '6%',
          customRender: (text, record, index) => {
            return this.statusMap[record.status]
          },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      visible: true,
      employeeLoading: true,
      targetKeys: [],
      employeeData: [],
      statusMap: {
        0: '正常',
        1: '已删除'
      }
    }
  },
  filters: {},
  created() {
    this.getList()
    this.getDicts('iot_insurancePolicy_type').then((response) => {
      this.insurancePolicyTypeOptions = response.data
    })
    this.getDicts('iot_del_yesno').then((response) => {
      this.statusOptions = response.data
    })
    this.getDicts('sys_job_status').then((response) => {
      this.delStatusOptions = response.data
    })
    if (this.$route.query.vehicleNo) {
      this.queryParam.vehicleNo = this.$route.query.vehicleNo
      this.handleQuery()
    }
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询保险管理列表 */
    getList() {
      this.loading = true
      this.queryParam.params = {}
      if (
        this.daterangeInsuranceStartDate !== null &&
        this.daterangeInsuranceStartDate !== '' &&
        this.daterangeInsuranceStartDate.length !== 0
      ) {
        this.queryParam.params['beginInsuranceStartDate'] = this.daterangeInsuranceStartDate[0]
        this.queryParam.params['endInsuranceStartDate'] = this.daterangeInsuranceStartDate[1]
      }
      if (
        this.daterangeInsuranceEndDate !== null &&
        this.daterangeInsuranceEndDate !== '' &&
        this.daterangeInsuranceEndDate.length !== 0
      ) {
        this.queryParam.params['beginInsuranceEndDate'] = this.daterangeInsuranceEndDate[0]
        this.queryParam.params['endInsuranceEndDate'] = this.daterangeInsuranceEndDate[1]
      }
      listInsurance(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    // 保单类型(字典)字典翻译
    insurancePolicyTypeFormat(row, column) {
      return this.selectDictLabel(this.insurancePolicyTypeOptions, row.insurancePolicyType)
    },
    // 是否删除 0否1是字典翻译
    statusFormat(row, column) {
      return this.selectDictLabel(this.statusOptions, row.status)
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.daterangeInsuranceStartDate = []
      this.daterangeInsuranceEndDate = []
      this.queryParam = {
        insuranceNo: undefined,
        vehicleNo: undefined,
        name: undefined,
        insurancePolicyType: undefined,
        status: '0',
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return changeStatusInsurance(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          return exportInsurance(that.queryParam).then((response) => {
            that.download(response.msg)
            that.$message.success('导出成功', 3)
          })
        },
        onCancel() {}
      })
    },
    // getEmployee() {
    //   // 获取人员列表
    //   const employeeData = []
    //   this.employeeLoading = true
    //   listEmployeeAccount({}).then(response => {
    //     this.list = response.rows
    //     this.total = response.total
    //     this.employeeLoading = false
    //     for (const item of response.rows) {
    //       const data = {
    //         key: item.id.toString(),
    //         title: item.name,
    //         description: 'description ' + item.name
    //       }
    //       employeeData.push(data)
    //     }
    //     this.employeeData = employeeData
    //     this.$forceUpdate()
    //     console.log('this.employeeData', this.employeeData)
    //   })
    // },
    filterOption(inputValue, option) {
      return option.description.indexOf(inputValue) > -1
    },
    handleChange(targetKeys, direction, moveKeys) {
      console.log(targetKeys, direction, moveKeys)
      this.targetKeys = targetKeys
    },
    handleSearch(dir, value) {
      console.log('search:', dir, value)
    },
    handleCancel() {
      this.visible = false
    },
    getCheckboxProps(record) {
      return {
        props: {
          disabled: record.status === 1 // Column configuration not to be checked
        }
      }
    }
  }
}
</script>
